<template>
  <div class="candidate-list">
    <div class="row">
      <div class="col-md-12">
        <div class="bgc-white bd bdrs-3 p-20 mB-20">
          <h2 class="c-grey-900">Candidates</h2>
          <div class="dataTable_wrapper clearfix">
            <div class="table-filter">
              <form @submit.prevent="onFilterTable">
                <label>
                  Filter
                  <input type="search" v-model="search" class="form-control" placeholder="">
                  <a v-if="search.length > 0" href="#" @click.prevent="onClearFilter">Clear filter</a>
                </label>
              </form>
            </div>
            <table class="table table-hover" cellspacing="0" width="100%">
              <thead>
                <tr>
                  <th
                    class="sort"
                    :class="{
                      sort_asc: candidatesSort.name && candidatesSort.name === 'ASC',
                      sort_desc: candidatesSort.name && candidatesSort.name === 'DESC',
                    }"
                    @click="onSortTable('name')"
                  >
                    Name
                  </th>
                  <th
                    class="sort"
                    :class="{
                      sort_asc: candidatesSort.role && candidatesSort.role === 'ASC',
                      sort_desc: candidatesSort.role && candidatesSort.role === 'DESC',
                    }"
                    @click="onSortTable('role')"
                  >
                    Role
                  </th>
                  <th
                    class="sort"
                    :class="{
                      sort_asc: candidatesSort.rating && candidatesSort.rating === 'ASC',
                      sort_desc: candidatesSort.rating && candidatesSort.rating === 'DESC',
                    }"
                    @click="onSortTable('rating')"
                  >
                    Rating
                  </th>
                  <th
                    class="sort"
                    :class="{
                      sort_asc: candidatesSort.current_company && candidatesSort.current_company === 'ASC',
                      sort_desc: candidatesSort.current_company && candidatesSort.current_company === 'DESC',
                    }"
                    @click="onSortTable('current_company')"
                  >
                    Company
                  </th>
                  <th
                    class="sort"
                    :class="{
                      sort_asc: candidatesSort.current_salary && candidatesSort.current_salary === 'ASC',
                      sort_desc: candidatesSort.current_salary && candidatesSort.current_salary === 'DESC',
                    }"
                    @click="onSortTable('current_salary')"
                  >
                    Salary
                  </th>
                  <th
                    class="sort"
                    :class="{
                      sort_asc: candidatesSort.status && candidatesSort.status === 'ASC',
                      sort_desc: candidatesSort.status && candidatesSort.status === 'DESC',
                    }"
                    @click="onSortTable('status')"
                  >
                    Status
                  </th>
                  <th
                    class="sort"
                    :class="{
                      sort_asc: candidatesSort.consultant && candidatesSort.consultant === 'ASC',
                      sort_desc: candidatesSort.consultant && candidatesSort.consultant === 'DESC',
                    }"
                    @click="onSortTable('consultant')"
                  >
                    Consultant
                  </th>
                  <th
                    class="sort"
                    :class="{
                      sort_asc: candidatesSort.updated_at && candidatesSort.updated_at === 'ASC',
                      sort_desc: candidatesSort.updated_at && candidatesSort.updated_at === 'DESC',
                    }"
                    @click="onSortTable('updated_at')"
                  >
                    Last updated
                  </th>
                  <th>Linkedin</th>
                  <th>Resume</th>
                  <th>Log</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="candidate in candidates" :key="candidate.id" class="candidate">
                  <td @click="onViewCandidate(candidate.id)">{{ `${candidate.firstname} ${candidate.lastname}` }}</td>
                  <td @click="onViewCandidate(candidate.id)">{{ candidate.role.name }}</td>
                  <td @click="onViewCandidate(candidate.id)">{{ candidate.rating }}</td>
                  <td @click="onViewCandidate(candidate.id)">{{ candidate.currentCompany }}</td>
                  <td @click="onViewCandidate(candidate.id)">{{ candidate.currentSalary }}</td>
                  <td @click="onViewCandidate(candidate.id)">{{ candidate.status.name }}</td>
                  <td @click="onViewCandidate(candidate.id)">{{ candidate.consultant.username }}</td>
                  <td @click="onViewCandidate(candidate.id)">{{ dayjs(candidate.updated_at).format('lll') }}</td>
                  <td v-if="candidate.linkedin.length">
                    <a :href="candidate.linkedin" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                  </td>
                  <td v-else />
                  <td>
                    <a v-if="candidate.resume" :href="candidate.resume" target="_blank" rel="noopener noreferrer">Re</a>
                    <a v-if="candidate.rirekisho" :href="candidate.rirekisho" target="_blank" rel="noopener noreferrer">Ri</a>
                    <a v-if="candidate.shokumukeirekisho" :href="candidate.shokumukeirekisho" target="_blank" rel="noopener noreferrer">Sh</a>
                  </td>
                  <td><a href="#" @click.prevent="onOpenLogModal(candidate)">Log</a></td>
                </tr>
              </tbody>
            </table>
            <div v-if="candidatesCount" class="table-info">
              {{ `Showing ${startCount + 1 } to ${candidatesCount >= (currentPage * perPage) ? (currentPage * perPage) : candidatesCount} of ${candidatesCount} entries` }}
            </div>
            <div class="table-paginate paging_simple_numbers">
              <router-link
                v-if="currentPage > 1"
                :to="{ path: '/candidates', query: { page: (currentPage - 1) }}"
                class="paginate_button previous"
              >
                Previous
              </router-link>
              <a v-else class="paginate_button previous disabled">Previous</a>
              <span>
                <router-link
                  v-for="page in nrOfPages"
                  :key="`page-${page}`"
                  :to="{ path: '/candidates', query: { page }}"
                  class="paginate_button"
                  :class="{'active': currentPage === page}"
                >
                  {{ page }}
                </router-link>
              </span>
              <router-link
                v-if="currentPage < nrOfPages"
                :to="{ path: '/candidates', query: { page: (currentPage + 1) }}"
                class="paginate_button next"
              >
                Next
              </router-link>
              <a v-else class="paginate_button next disabled">Next</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LogModal />
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import router from '@/router'
import LogModal from '@/components/LogModal.vue'

dayjs.extend(LocalizedFormat)

export default defineComponent({
  name: 'CandidateList',
  components: {
    LogModal,
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const perPage = 20

    const candidates = computed(() => store.state.candidates)
    const candidatesCount = computed(() => store.state.candidatesCount)
    const candidatesSort = computed(() => store.state.candidatesSort)
    const currentPage = computed(() => parseInt(route.query.page, 10) || 1)
    const nrOfPages = computed(() => Math.ceil(candidatesCount.value / perPage))
    const startCount = computed(() => (currentPage.value - 1) * perPage)

    const search = ref('')

    const onViewCandidate = (candidateId) => {
      router.push({ path: `/candidates/${candidateId}` })
    }

    const onClearFilter = () => {
      search.value = ''
      store.commit('SET_CANDIDATES_FILTER', '')
      store.dispatch('onFetchCandidatesCount')
      store.dispatch('onFetchCandidates', { start: startCount.value, limit: perPage })
    }

    const onFilterTable = () => {
      store.commit('SET_CANDIDATES_FILTER', search.value)
      store.dispatch('onFetchCandidatesCount')
      store.dispatch('onFetchCandidates', { start: startCount.value, limit: perPage })
    }

    const onSortTable = (sortBy) => {
      store.commit('SET_CANDIDATES_SORT', sortBy)
      store.dispatch('onFetchCandidates', { start: startCount.value, limit: perPage })
    }

    const onOpenLogModal = (candidate) => {
      console.log('onOpenLogModal', candidate)
      store.commit('SET_LOG_MODAL', { show: true, candidate })
      store.dispatch('onFetchLogs', candidate.id)
    }

    onMounted(() => {
      store.dispatch('onFetchCandidatesCount')
      store.dispatch('onFetchCandidates', { start: startCount.value, limit: perPage })
    })

    watch(() => route.query, () => {
      store.dispatch('onFetchCandidates', { start: startCount.value, limit: perPage })
    })

    return {
      dayjs,
      candidates,
      candidatesCount,
      candidatesSort,
      currentPage,
      nrOfPages,
      perPage,
      search,
      startCount,
      onClearFilter,
      onFilterTable,
      onOpenLogModal,
      onSortTable,
      onViewCandidate,
    }
  },
})
</script>

<style scoped lang="scss">
table {
  display: block;
  overflow: auto;
  // white-space: nowrap;

  thead,
  tbody {
    // display: table;
    width: 100%;
  }

  thead {
    th {
      &.sort {
        cursor: pointer;
        padding-right: 26px;
        position: relative;

        &:before,
        &:after {
          content: "\f0de";
          font-family: 'FontAwesome';
          font-weight: 900;
          font-size: 16px;
          position: absolute;
          top: 10px;
          right: 12px;
          opacity: 0.3;
        }

        &:after {
          content: "\f0dd";
          margin-top: 2px;
        }
      }

      &.sort_asc:before,
      &.sort_desc:after {
        opacity: 1;
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;
    }

    td {
      a {
        margin-right: 5px;
      }
    }
  }
}

.table-filter {
  border-top: 1px solid rgba(0, 0, 0, 0.0625);
  padding: 0.75rem 0;

  label {
    font-weight: 700;
    width: 100%;
  }

  input {
    width: 100%;
  }
}

.table-info {
  background: transparent;
  float: left;
}

.table-paginate {
  float: right;

  a.active {
    font-weight: 700;
  }

  .paginate_button {
    padding: 6px;
    border-radius: 2px;
    margin-right: 10px;
    transition: all .2s ease-in-out;
    text-decoration: none;

    &.prev,
    &.next {
      padding: 6px 12px;
    }

    &.disabled {
      color: #72777a;
    }
  }
}
</style>
